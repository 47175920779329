import { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import clone from 'clone-deep'
import { nav } from 'tiny-react-router'
import { ToastContainer, toast } from 'react-toastify'
import { 
  AiOutlineDelete,
  AiOutlineCloseSquare 
} from 'react-icons/ai'
import { 
  format_date,
  format_phone_number,
  getNextEventDate
} from '../../shared/utils'
import { 
  useOrderStore,
  useConfigStore,
  useAccountStore,
  useProductStore,
  useSubscriptionStore 
} from '../../shared/state'
import CampaignSection from '../../shared/components/CampaignSection'
import UtsolgtImg from '../../shared/graphics/utsolgt.png'
import 'react-toastify/dist/ReactToastify.css'
import './toast-mods.css'

export default function Main() {
  const orders = useOrderStore(s => s.orders)
  const account = useAccountStore(s => s.account)
  const products = useProductStore(s => s.products)
  const toastRef = useRef(null)
  //const setOrders = useOrderStore(s => s.setOrders)
  const setAccount = useAccountStore(s => s.setAccount)
  const fetchOrders = useOrderStore(s => s.fetchOrders)
  const transportFee = useConfigStore(s => s.transportFee)
  const subscriptions = useSubscriptionStore(s => s.subscriptions)
  const setSubscriptions = useSubscriptionStore(s => s.setSubscriptions)
  const postalCodesNoFee = useConfigStore(s => s.postalCodesNoFee)
  const subscriptionDiscount = useConfigStore(s => s.subscriptionDiscount)
  const [adding_sub, set_adding_sub] = useState(false)
  const [adding_order, set_adding_order] = useState(false)
  const [selected_products, set_selected_products] = useState([])
  const [subscription_interval, set_subscription_interval] = useState('ukentlig')
  const [selected_details_product, set_selected_details_product] = useState(null)

  let add_transport_fee = true
  try {
    const postal_code = account?.data?.address?.postal_code
    if (postal_code) add_transport_fee = postalCodesNoFee.indexOf(parseInt(postal_code)) < 0
  } catch(e) {}

  useEffect(() => {
    const fullUrl = window.location.href;
    const queryString = fullUrl.includes('?') ? fullUrl.split('?')[1] : '';
    const urlParams = new URLSearchParams(queryString);
    const vippsresult = urlParams.get('vippsresult')
    setTimeout(() => {
      if (vippsresult === 'Betalt' && !toast.isActive(toastRef.current)) 
        toastRef.current = toast('Ordre mottat. Vi vil levere din ordre så snart vi kan. Du kan følge status på denne siden.', { autoClose: 10000 })
      if (vippsresult === 'Problem' && !toast.isActive(toastRef.current)) 
        toastRef.current = toast.error('Det oppstod et problem med din vipps betaling. Kontakt support hvis problemet fortsetter.', { autoClose: 10000 })
      if (vippsresult === 'nosublink' && !toast.isActive(toastRef.current)) 
        toastRef.current = toast('Abonnements-lenken er allerede brukt.', { autoClose: 10000 })
      }, Math.random()*500)
    const campaignresult= urlParams.get('campaignresult')
    setTimeout(() => {
      if (campaignresult === 'no_more_slots' && !toast.isActive(toastRef.current)) 
        toastRef.current = toast('Det var desverre ingen flere plasser igjen på kampanjen. Bedre lykke neste gang 😬', { autoClose: 10000 })
      if (campaignresult === 'no_such_campaign' && !toast.isActive(toastRef.current)) 
        toastRef.current = toast('Ukjent kampanje 🤔', { autoClose: 10000 })
      }, Math.random()*500)
    const fetchOrdersInterval = setInterval(async () => {
      await fetchOrders()
    }, 3000)
    return () => clearInterval(fetchOrdersInterval)
  }, [fetchOrders])

  const handleLogout = async () => {
    const logout_res = await fetch(`/api/logout`)
    if (logout_res.status !== 200) return
    setAccount(null)
    nav('/')
  }

  const handleSelectProduct = (product_id) => {
    let prod = products.find(p => p.id === product_id) 
    if (prod?.data?.sold_out) return
    let p = clone(prod)
    p.grind = 'bønner'
    set_selected_products(selected_products.concat(p))
  }

  const handleUpdateAccountConfig = async (key, value, include) => {
    let current_contact = (account?.config?.contact || [])
    let current_subscription_contact = (account?.config?.subscription_contact || [])
    if (key === 'contact') {
      if (include) current_contact.push(value)
      else current_contact = current_contact.filter(c => c !== value)
      current_contact = [...new Set(current_contact)]
    }
    if (key === 'subscription_contact') {
      current_subscription_contact = [value]
    }
    const updated_account = await fetch(`/api/me`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        contact: current_contact,
        subscription_contact: current_subscription_contact
      })
    })
    if (!updated_account.ok) return toast.error('Kunne ikke oppdatere preferanser')
    const _account = await updated_account.json()
    setAccount(_account)
  }

  const handleAddingSub = () => {
//    toast('Abonnement er ikke helt klart enda...')
    set_adding_sub(!adding_sub)
  }

  const handleCreateNewSubcription = async () => {
    const payload = {
      interval: subscription_interval,
      products: selected_products,
      transport_fee: add_transport_fee ? transportFee : 0
    }
    const sub_res = await fetch(`/api/subscriptions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    if (sub_res.status !== 200) return toast.error('Kunne ikke opprette abonnement')
    const subs = await sub_res.json()
    set_subscription_interval('ukentlig')
    set_selected_products([])
    set_adding_sub(false)
    setSubscriptions(subs)
    let channel = 'melding'
    try { 
      channel = account.config.subscription_contact[0] 
      if (channel === 'sms') channel = 'SMS'
      if (channel === 'email') channel = 'epost'
    } catch(e) {}
    toast(`Abonnement opprettet. Du vil straks motta en ${channel} med lenke til første ordre.`, { autoClose: 10000 })
  }

  const handleRemoveSubscription = async (sid) => {
    const sub_res = await fetch(`/api/subscriptions`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: sid
      })
    })
    if (sub_res.status !== 200) return toast.error('Kunne ikke slette abonnement')
    const subs = await sub_res.json()
    setSubscriptions(subs)
    toast('Abonnement slettet')
  }

  const handleDeleteSelectedProduct = (i) => {
    let sel_prods = [
      ...selected_products.slice(0, i), 
      ...selected_products.slice(i+ 1)
    ];
    set_selected_products(sel_prods)
  }

  const handleSetSelectedProductGrind = (i, grind) => {
    const sel_prods = selected_products.map((p,_i) => {
      if (_i === i) p.grind = grind
      return p
    })
    set_selected_products(sel_prods)
  }

  const handleCreateNewOrder = async () => {
    const payload = {
      products: selected_products,
      transport_fee: add_transport_fee ? transportFee : 0
    }
    const order_res = await fetch(`/api/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    if (order_res.status !== 200) return toast.error('Kunne ikke opprette ordren') 
    const order_json = await order_res.json()
    window.location.href = order_json.redirectUrl
//    set_selected_products([])
//    set_adding_order(false)
//    setOrders(orders)
//    toast('Ordre mottat. Du vil straks motta en Vipps forespørsel for denne ordren.', { autoClose: 10000 })
  }

  const subRows = subscriptions.map(s => {
    const prods = (s?.data?.products || []).map(p => {
      return products.filter(_p => _p?.id === p?.id)[0]
    })
    if (prods.indexOf(undefined) >= 0) return null // Product not found
    // TODO: Old abbos need to DISPLAY the new name & logo... Admin page.. Here already works?
    // TODO: Indicate discount! price = Math.floor(price * 0.9) use config.subscriptionDiscount
    const price = prods.reduce((sum, p) => sum += p?.data?.price, 0) + (s?.data?.transport_fee || 0)
    const discounted_price = Math.floor(price * subscriptionDiscount)
    const prodItems = prods.map((p,i) => (
      <ProductItem key={`${p.id}${i}`} onClick={() => set_selected_details_product(p)}>
        <img src={p?.data?.bag} alt="bag" />
      </ProductItem>
    ))
    let next_order_date = 'Ukjent'
    try {
      next_order_date = getNextEventDate(new Date(s?.data?.last_order_date), s?.data?.interval)
    } catch(e) {}
    return (
      <SubscriptionRow key={s.id}>
        <SubscriptionRowProducts>{prodItems}</SubscriptionRowProducts>
        <SubscriptionRowInterval>{s?.data?.interval}</SubscriptionRowInterval>
        <SubscriptionRowDates>{format_date(new Date(s?.created_at))}</SubscriptionRowDates>
        <SubscriptionRowInterval>{format_date(next_order_date, { failed: 'Ikke bestemt' })}</SubscriptionRowInterval>
        <SubscriptionRowInterval>{`NOK ${discounted_price},-`}</SubscriptionRowInterval>
        <SubscriptionRowRemove><AiOutlineDelete onClick={() => handleRemoveSubscription(s.id)} size="1.3em" /></SubscriptionRowRemove>
      </SubscriptionRow>
    )
  })

  let prodDetails = null
  if  (selected_details_product) prodDetails = (
    <ProductDetailsContainer>
      <ProductItem size={150}>
        <img className="productImage" src={selected_details_product?.data?.bag} alt="bag" />
      </ProductItem>
      <ProductDetails>
        <div className="name">{selected_details_product?.data?.name}</div>
        <div className="grind">{selected_details_product?.grind}</div>
        <a href={selected_details_product?.data?.link} target="_blank" rel="noreferrer">Les mer...</a>
      </ProductDetails>
      <ProductDetailsClose>
        <AiOutlineCloseSquare size="1.5em" onClick={() => set_selected_details_product(null) }/>
      </ProductDetailsClose>
    </ProductDetailsContainer>
  )

  const orderRows = orders
    .filter(s => s?.data?.status !== 'Slettet')
    .map(s => {
      const prods = (s?.data?.products || [])
      let price = prods.reduce((sum, p) => sum += p?.data?.price, 0)+ (s?.data?.transport_fee || 0)
      if (s?.data?.price_paid) price = s?.data?.price_paid
      if (s?.data?.subscription_price_paid) price = s?.data?.subscription_price_paid
      const prodItems = prods.map((p,i) => (
        <ProductItem key={`${p.id}${i}`} onClick={() => set_selected_details_product(p)}>
          <img src={p?.data?.bag} alt="bag" />
        </ProductItem>
      ))
      return (
        <SubscriptionRow key={s.id}>
          <SubscriptionRowProducts>{prodItems}</SubscriptionRowProducts>
          <SubscriptionRowInterval>{format_date(new Date(s?.created_at))}</SubscriptionRowInterval>
          <SubscriptionRowInterval>{`NOK ${price},-`}</SubscriptionRowInterval>
          <SubscriptionRowInterval>{s?.data?.status}</SubscriptionRowInterval>
        </SubscriptionRow>
      )
    })

  const productList = products.filter(p => p?.data?.bag)
    .sort((a,b) => {
      if (a?.data?.order > b?.data.order) return 1
      if (a?.data?.order < b?.data.order) return -1
      return 0
    })
    .map(p => {
      const selected = selected_products.map(p => p.id).indexOf(p.id) >= 0
      return (
        <ProductItem key={p.id} selected={selected} onClick={() => handleSelectProduct(p.id)} soldout={`${p?.data?.sold_out}`}>
          <img className="productImage" src={p?.data?.bag} alt="bag" />
          { p?.data?.sold_out &&
            <UtsolgtImage src={UtsolgtImg} alt="utsolgt" />
          }
        </ProductItem>
      )
    })

  let selected_products_price = 0
  const selected_products_text = selected_products.map((sp,i) => {
    const product = products.find(p => p.id === sp.id)
    selected_products_price = selected_products_price += product?.data?.price
    return (
      <SelectedProduct key={product.id+i}>
        <SelectedProductName>{product?.data?.name}</SelectedProductName>
        <SelectedProductCost>{`${product?.data?.price},-`}</SelectedProductCost>
        <SelectedProductGrind>
          <select value={sp?.grind} onChange={(e) => handleSetSelectedProductGrind(i, e.target.value)}>
            <option value="bønner">Bønner</option>
            <option value="filtermalt">Filtermalt</option>
            <option value="kokmalt">Kokmalt</option>
            <option value="finmalt">Finmalt</option>
          </select>
        </SelectedProductGrind>
        <SelectedProductDelete onClick={() => handleDeleteSelectedProduct(i)}><AiOutlineDelete /></SelectedProductDelete>
      </SelectedProduct>
    )
  })
  if (add_transport_fee) {
    selected_products_text.push((
        <SelectedProduct key="transport">
          <SelectedProductName>Frakt</SelectedProductName>
          <SelectedProductCost>{`${transportFee},-`}</SelectedProductCost>
          <div style={{ flex: 'auto' }}></div>
        </SelectedProduct>
    ))
    selected_products_price += transportFee
  }
  if (adding_sub) {
    let discounted_price = Math.floor(selected_products_price * subscriptionDiscount)
    let discount = selected_products_price - discounted_price
    selected_products_text.push((
        <SelectedProduct key="discount">
          <SelectedProductName>Rabatt</SelectedProductName>
          <SelectedProductCost>{`- ${discount},-`}</SelectedProductCost>
          <div style={{ flex: 'auto' }}></div>
        </SelectedProduct>
    ))
    selected_products_price = discounted_price
  }

  return (
    <Container modal={selected_details_product}>
      <AccountSection>
        <SectionHeadingRow>
          <SectionHeading>Konto</SectionHeading>
          <AccountButtons>
            <button className="purple" onClick={handleLogout}>Logg ut</button>
          </AccountButtons>
        </SectionHeadingRow>
        <AccountInfo>
          <AccountInfoSection>
            <AccountElement>
              <label>Navn</label>
              <div>{account?.data?.name}</div>
            </AccountElement>
            <AccountElement>
              <label>Adresse</label>
              <div>{account?.data?.address?.street_address}</div>
            </AccountElement>
          </AccountInfoSection>
          <AccountInfoSection>
            <AccountElement>
              <label>Telefon</label>
              <div>{format_phone_number(account?.data?.phone_number)}</div>
            </AccountElement>
            <AccountElement>
              <label>Epost</label>
              <div>{account?.data?.email}</div>
            </AccountElement>
          </AccountInfoSection>
        </AccountInfo>
        <AccountSettings>
          <h3>Preferanser</h3>
          <AccountInfo>
            <AccountElement>
              <label>Kontakt</label>
              <p>Bøllefrø kan kontakte meg med tilbud, nye produkter, etc.</p>
              <div>
                <label><input type="checkbox" checked={(account?.config?.contact || []).indexOf('email') >= 0} onChange={(e) => handleUpdateAccountConfig('contact', 'email', e.target.checked)} />Epost</label>
                <label><input type="checkbox" checked={(account?.config?.contact || []).indexOf('sms') >= 0} onChange={(e) => handleUpdateAccountConfig('contact', 'sms', e.target.checked)} />SMS</label>
              </div>
            </AccountElement>
            <AccountElement>
              <label>Abonnement</label>
              <p>Jeg ønsker å motta lenker for betaling av abonnement på:</p>
              <div>
                <label><input type="radio" checked={(account?.config?.subscription_contact || []).indexOf('email') >= 0} onChange={(e) => handleUpdateAccountConfig('subscription_contact', 'email', e.target.checked)} />Epost</label>
                <label><input type="radio" checked={(account?.config?.subscription_contact || []).indexOf('sms') >= 0} onChange={(e) => handleUpdateAccountConfig('subscription_contact', 'sms', e.target.checked)} />SMS</label>
              </div>
            </AccountElement>
          </AccountInfo>
        </AccountSettings>
        <AccountInfo>
          { add_transport_fee &&
            <AccountTransportInfo>Du bor i et område uten gratis levering og vi legger til <b>NOK {transportFee},- for frakt</b> på alle dine ordre.</AccountTransportInfo>
          }
          { !add_transport_fee &&
            <AccountTransportInfo><b>Du bor i et område med gratis levering!</b><br/>Vi legger derfor ikke til frakt på dine ordre.</AccountTransportInfo>
          }
        </AccountInfo>
      </AccountSection>
      <CampaignSection />
      <SubscriptionSection>
        <SectionHeadingRow>
          <SectionHeading>Abonnement</SectionHeading>
          <button className="purple" onClick={handleAddingSub}>Legg til</button>
        </SectionHeadingRow>
        <p>
          Abonnement er løpende ordre på kaffe.<br/>
          Vi oppretter automatisk ordre for deg for det intervallet og de produktene du har valgt.<br/>
          Du vil motta en sms eller epost (velg over) for hver ordre. Meldingen inneholder en lenke som åpner en Vipps forespørsel.<br/>
          Dersom du ikke trenger leveranse den aktuelle perioden, er det bare å ikke klikke på lenken.<br />
          Enkelt!
        </p>
        { adding_sub &&
          <AddSubscriptionContainer>
            <h3>Nytt abonnement</h3>
            <AddSubOptionsContainer>
              <AddSubOption>
                <label>Velg produkt(er)</label>
                <ProductItemContainer>
                  {productList}
                </ProductItemContainer>
              </AddSubOption>
              <AddSubOption>
                <label>Velg intervall</label>
                <IntervalContainer>
                  <Interval selected={subscription_interval === 'ukentlig'} onClick={() => set_subscription_interval('ukentlig')}>Ukentlig</Interval>
                  <Interval selected={subscription_interval === 'annenhver uke'} onClick={() => set_subscription_interval('annenhver uke')}>Annenhver uke</Interval>
                  <Interval selected={subscription_interval === 'månedlig'} onClick={() => set_subscription_interval('månedlig')}>Månedlig</Interval>
                </IntervalContainer>
              </AddSubOption>
              { selected_products.length > 0 && subscription_interval !== '' &&
              <AddSubOption>
                <label>Oppsummering</label>
                <p>Du har valgt {subscription_interval} leveranse av:</p>
                {selected_products_text}
                <p>NOK {selected_products_price},-</p>
                <button className="purple" onClick={handleCreateNewSubcription}>Bestill</button>
              </AddSubOption>
              }
            </AddSubOptionsContainer>
          </AddSubscriptionContainer>
        }
        {subRows}
      </SubscriptionSection>
      <OrderSection>
        <SectionHeadingRow>
          <SectionHeading>Ordre</SectionHeading>
          <button className="purple" onClick={() => set_adding_order(!adding_order)}>Legg til</button>
        </SectionHeadingRow>
        <p>
          Her kan du bestille kaffe.<br />
          Dine tidligere bestillinger listes under (også abonnements-bestillinger).<br/>
          Du mottar en Vipps forespørsel for hver ordre.<br />
          Enkelt!
        </p>
        { adding_order &&
          <AddSubscriptionContainer>
            <h3>Ny ordre</h3>
            <AddSubOptionsContainer>
              <AddSubOption>
                <label>Velg produkt(er)</label>
                <ProductItemContainer>
                  {productList}
                </ProductItemContainer>
              </AddSubOption>
              { selected_products.length > 0 &&
              <AddSubOption>
                <label>Oppsummering</label>
                <p>Du har valgt følgende produkter:</p>
                {selected_products_text}
                <p>NOK {selected_products_price},-</p>
                <button className="purple" onClick={handleCreateNewOrder}>Bestill</button>
              </AddSubOption>
              }
            </AddSubOptionsContainer>
          </AddSubscriptionContainer>
        }
        {orderRows}
      </OrderSection>
      {prodDetails}
      <ToastContainer 
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${props => props.modal ? `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
  };
  ` : ''}
`

const SectionHeadingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SectionHeading = styled.h1`
  margin: 0;
  padding: 20px 0px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const AccountSection = styled(Section)`
  color: white;
  background-color: var(--color-vipps);
  padding: 20px;
  padding-bottom: 30px;
`

const SubscriptionSection = styled(Section)`
  background-color: var(--color-splash-yellow);
  padding: 20px;

  & p {
    font-size: 1em;
    line-height: 1.5em;
  }
`

const OrderSection = styled(Section)`
  color: white;
  background-color: var(--color-splash-purple);
  padding: 20px;

  & p {
    font-size: 1em;
    line-height: 1.5em;
  }
`

const AccountInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const AccountSettings = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AccountInfoSection = styled.div`
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`

const AccountElement = styled.div`
  margin: 10px;
  & label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  & p {
    font-size: 0.9em;
    max-width: 150px;
  }
`

const AccountButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AddSubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px dotted black;
  padding-bottom: 20px;
`

const AddSubOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-family: monospace;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const AddSubOption = styled.div`
  display: flex;
  flex-direction: column;
  & label {
    padding-bottom: 10px;
  }
  & p {
    padding: 0;
  }
  & button {
    width: 100px;
  }
`

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  & .productImage {
    width: ${props => `${props?.size || 80}px`};
    @media (max-width: 768px) {
      width: ${props => `${props?.size || 57}px`};
    }
    border: 1px solid black;
    ${props => props.soldout === 'true' ? 'filter: grayscale(100%);' : ''}
  }
`

const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media (max-width: 768px) {
    font-size: 0.9em;
    gap: 5px;
  }
`

const SubscriptionRow = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted black;
  font-family: monospace;
  &:last-child {
    border-bottom: 0;
    padding-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`

const SubscriptionRowProducts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 200px;
  gap: 10px;
  & img {
    width: 40px;
  }
  @media (max-width: 768px) {
    width: 100px;
    & img {
      width: 30px;
    }
  }
`

const SubscriptionRowInterval = styled.div`
  text-transform: capitalize;
  min-width: 100px;
  @media (max-width: 768px) {
    min-width: 30px;
  }
`

const SubscriptionRowDates = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const SubscriptionRowRemove = styled.div`
  cursor: pointer;
`

const SelectedProduct = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2px;
  width: 100%;
  gap: 15px;
 
  & input {
    width: 30px;
  }
`

const SelectedProductName = styled.div`
  width: 100px;
`
const SelectedProductCost = styled.div`
  width: 50px;
  text-align: right;
`
const SelectedProductGrind = styled.div``
const SelectedProductDelete = styled.div`
  cursor: pointer;
`

const IntervalContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Interval = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 5px;
  border: 1px solid black;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: bold;
  ${props => props.selected ? 'background-color: var(--color-vipps); color: white;' : ''}
`

const ProductDetailsContainer = styled.div`
  position: fixed;
  width: 300px;
  top: 50%;
  left: 50%;
  border: 1px solid black;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: var(--color-splash-yellow);
  padding: 2%;
  display: flex;
  gap: 10px;
`

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  & div.name {
    font-weight: bold;
  }

  & div.grind {
    text-transform: capitalize;
  }

  & p {
    font-size: 0.9em;
  }
`

const ProductDetailsClose = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`

const AccountTransportInfo = styled.div`
  padding: 10px;
  max-width: 35%; 
  text-align: center;
  font-style: italic;
  @media (max-width: 768px) {
    max-width: 70%; 
  }
`

const UtsolgtImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  border: 0px!important;
`
