import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { differenceInDays } from 'date-fns'
import { 
  AiOutlineCloseSquare 
} from 'react-icons/ai'
import { 
  format_date,
  getNextEventDate
} from '../../shared/utils'
import 'react-datepicker/dist/react-datepicker.css'

export default function Main() {
  const [subs, setSubs] = useState([])
  const [orders, setOrders] = useState([])
  const [to, setTo] = useState(new Date())
  const [tab, setTab] = useState('orders')
  const [from, setFrom] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
  const [statusFilter, setStatusFilter] = useState('')
  const [selected_details_order, set_selected_details_order] = useState(null)

  useEffect(() => {
    const fetch_admin_orders = async () => {
      console.log('fetching...')
      const _from = new Date(from)
      _from.setDate(_from.getDate() - 1)
      const admin_orders_res = await fetch(`/api/admin/orders?from=${_from}&to=${to}&status=${statusFilter}`)
      if (!admin_orders_res.ok) return
      const _orders = await admin_orders_res.json()
      setOrders(_orders)
      const admin_subs_res = await fetch(`/api/admin/subscriptions`)
      if (!admin_subs_res.ok) return
      const _subs = await admin_subs_res.json()
      setSubs(_subs)
    }
    fetch_admin_orders()
  }, [to, from, statusFilter])

  const handleOrderStatusChange = async (order, status) => {
    const order_res = await fetch(`/api/admin/orders/${order.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status: status })
    })
    if (!order_res.ok) return toast.error('Kunne ikke oppdatere status')
    const _orders = orders.map(o => {
      if (o.id !== order.id) return o
      o.data.status = status
      return o
    })
    setOrders(_orders)
    toast('Status oppdatert')
  }

  const statusOptions = ['Mottat','Forespurt','Betalt','Levert','Avvist','Problem','Slettet'].map(s => {
    return (
      <option key={s} value={s}>{s}</option>
    )
  })

  const orderRows = orders
    .filter(o => {
      if (statusFilter === '') return o?.data?.status !== 'Slettet'
      return true
    })
    .map(s => {
      const prods = (s?.data?.products || [])
      let price = prods.reduce((sum, p) => sum += p?.data?.price, 0)
      if (s?.data?.price_paid) price = s?.data?.price_paid
      if (s?.data?.subscription_price_paid) price = s?.data?.subscription_price_paid
      const prodItems = prods.map((p,i) => (
        <ProductItem key={`${p.id}${i}`} onClick={() => set_selected_details_order(s)}>
          <img src={p?.data?.bag} alt="bag" />
        </ProductItem>
      ))
      return (
        <SubscriptionRow key={s.id}>
          <SubscriptionRowProducts>{prodItems}</SubscriptionRowProducts>
          <SubscriptionRowInner>
            <CustomerInfo>
              <div>{s?.customerdata?.name}</div>
              <div>{s?.customerdata?.email}</div>
              <div>{s?.customerdata?.phone_number}</div>
              <div>{s?.customerdata?.address?.formatted}</div>
            </CustomerInfo>
          </SubscriptionRowInner>
          <SubscriptionRowInterval>{format_date(new Date(s?.created_at))}</SubscriptionRowInterval>
          <SubscriptionRowInterval>{`NOK ${price},-`}</SubscriptionRowInterval>
          <SubscriptionRowStatus>
            <select value={s?.data?.status} onChange={(e) => handleOrderStatusChange(s, e.target.value)}>
              {statusOptions}
            </select>
          </SubscriptionRowStatus>
        </SubscriptionRow>
      )
    })

  const subRows = subs
    .map(s => {
      const prods = (s?.data?.products || [])
      const prodItems = prods.map((p,i) => (
        <ProductItem key={`${p.id}${i}`} onClick={() => set_selected_details_order(s)}>
          <img src={p?.data?.bag} alt="bag" />
        </ProductItem>
      ))
      let days_since_last_order = 'Ingen'
      let next_order_date = 'Ukjent'
      try {
        days_since_last_order = differenceInDays(new Date(), new Date(s?.data?.last_order_date))
        if (isNaN(days_since_last_order)) days_since_last_order = 'Ukjent'
        next_order_date = getNextEventDate(new Date(s?.data?.last_order_date), s?.data?.interval)
      } catch(e) {}
      return (
        <SubscriptionRow key={s.id}>
          <SubscriptionRowProducts>{prodItems}</SubscriptionRowProducts>
          <SubscriptionRowInner>
            <CustomerInfo>
              <div>{s?.customerdata?.name}</div>
              <div>{s?.customerdata?.email}</div>
              <div>{s?.customerdata?.phone_number}</div>
            </CustomerInfo>
          </SubscriptionRowInner>
          <SubscriptionRowInterval>{s?.data?.interval}</SubscriptionRowInterval>
          <SubscriptionRowInterval>{days_since_last_order}</SubscriptionRowInterval>
          <SubscriptionRowInterval>{format_date(next_order_date, { failed: 'Ikke bestemt' })}</SubscriptionRowInterval>
          <SubscriptionRowInterval>{format_date(new Date(s?.created_at))}</SubscriptionRowInterval>
        </SubscriptionRow>
      )
    })

  let prodDetails = null
  if  (selected_details_order) prodDetails = (
    <OrderDetailsContainer>
      <OrderDetailsHeader>
        <div><b>Ordre id:</b>{selected_details_order.id.split('-')[0]}</div>
      { selected_details_order.subscription &&
        <div><b>Abonnement:</b>{selected_details_order.subscription.split('-')[0]}</div>
      }
      </OrderDetailsHeader>
      { selected_details_order.data.products.map(selected_details_product => (
      <ProductDetailsContainer>
        <ProductItem size={50}>
          <img src={selected_details_product?.data?.bag} alt="bag" />
        </ProductItem>
        <ProductDetails>
          <div className="name">{selected_details_product?.data?.name}</div>
          <div className="grind">{selected_details_product?.grind}</div>
        </ProductDetails>
      </ProductDetailsContainer>
      ))}
      <ProductDetailsClose>
        <AiOutlineCloseSquare size="1.5em" onClick={() => set_selected_details_order(null) }/>
      </ProductDetailsClose>
    </OrderDetailsContainer>
  )

  return (
    <Container>
      <FilterContainer>
        <div>
          <label>Fra</label>
          <DatePicker selected={from} onChange={(date) => setFrom(date)} dateFormat="dd.MM.yyyy" />
        </div>
        <div>
          <label>Til</label>
          <DatePicker selected={to} onChange={(date) => setTo(date)} dateFormat="dd.MM.yyyy" />
        </div>
        <div>
          <label>Status</label>
          <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value=''>Alle</option>
            {statusOptions}
          </select>
        </div>
      </FilterContainer>
      <TabsContainer>
        <Tab selected={tab === 'orders'} onClick={() => setTab('orders')}>Ordre</Tab>
        <Tab selected={tab === 'subscriptions'} onClick={() => setTab('subscriptions')}>Abonnement</Tab>
      </TabsContainer>
      { tab === 'orders' &&
      <OrderSection>
        {orderRows}
      </OrderSection>
      }
      { tab === 'subscriptions' &&
      <OrderSection>
        {subRows}
      </OrderSection>
      }
      {prodDetails}
      <ToastContainer 
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${props => props.modal ? `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
  };
  ` : ''}
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderSection = styled(Section)`
  color: white;
  background-color: var(--color-splash-purple);
  padding: 20px;

  & p {
    font-size: 0.8em;
    line-height: 1.5em;
  }
`

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  & img {
    width: ${props => `${props?.size || 80}px`};
    border: 1px solid black;
  }
`

const SubscriptionRow = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted black;
  font-family: monospace;
  &:last-child {
    border-bottom: 0;
    padding-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 0.7em;
  }
`

const SubscriptionRowProducts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 200px;
  gap: 10px;
  & img {
    width: 40px;
  }
  @media (max-width: 768px) {
    width: 100px;
    & img {
      width: 30px;
    }
  }
`

const SubscriptionRowInner = styled.div`
  min-width: 100px;
  @media (max-width: 768px) {
    min-width: 30px;
  }
`

const SubscriptionRowStatus = styled(SubscriptionRowInner)`
  & select {
    font-size: 1em;
    background-color: transparent;
    color: white;
    padding: 2px;
  }

  & option {
    background-color: var(--color-splash-purple);
  }
`

const SubscriptionRowInterval = styled(SubscriptionRowInner)`
  text-transform: capitalize;
  @media (max-width: 768px) {
    display: none;
  }
`

const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 280px; 
  @media (max-width: 768px) {
    font-size: 1.2em;
    width: 180px;
  }
`
const FilterContainer = styled.div`
  background-color: var(--color-splash-yellow);
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  & > div {
    display: flex;
    flex-direction: column;

    & > label {
      font-weight: bold;
    }

    & input, select {
      font-size: 1em;
    }
  }
`

const OrderDetailsContainer = styled.div`
  position: fixed;
  width: 300px;
  top: 50%;
  left: 50%;
  border: 1px solid black;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: var(--color-splash-yellow);
  padding: 2%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const OrderDetailsHeader = styled.div`
  margin-bottom: 10px; 
  & b {
    margin-right: 10px;
  }
`

const ProductDetailsContainer = styled.div`
  display: flex;
  gap: 10px;
`

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  & div.name {
    font-weight: bold;
  }

  & div.grind {
    text-transform: capitalize;
  }

  & p {
    font-size: 0.9em;
  }
`

const ProductDetailsClose = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`

const TabsContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 20px;
  background-color: var(--color-splash-purple);
`
const Tab = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-weight: bold;
  color: white;
  ${props => props?.selected ? 'background-color: var(--color-splash-yellow); color: black;' : ''}
  cursor: pointer;
`
